import PropTypes from "prop-types";
import { pluck } from "ramda";
import React, { useMemo } from "react";
import { useQuery } from "react-query";
import Select from "react-select";

export const EmailSelect = ({
  value,
  onChange,
  isMulti,
  className,
  dataLoader,
}) => {
  const { data, isLoading } = useQuery(
    [dataLoader.uniqueKey],
    dataLoader.loader,
    {
      refetchOnWindowFocus: false,
      retry: false,
    }
  );

  const responsible = data?.data || [];

  const handleChange = (input) => {
    if (isMulti) {
      const emails = pluck("value", input || []);
      onChange(emails);
    } else {
      onChange(input?.value);
    }
  };

  const options = useMemo(
    () =>
      responsible.map(({ name, email }) => ({
        value: email,
        label: `${name} <${email}>`,
      })),
    [responsible]
  );

  return (
    <Select
      isClearable
      isMulti={isMulti}
      isLoading={isLoading}
      options={options}
      value={
        value == null ? null : options.find((item) => item.value === value)
      }
      onChange={handleChange}
      className={className}
    />
  );
};

EmailSelect.propTypes = {
  isMulti: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  onChange: PropTypes.func.isRequired,
  className: PropTypes.string,
  dataLoader: PropTypes.shape({
    uniqueKey: PropTypes.string,
    loader: PropTypes.func,
  }).isRequired,
};
