import { MsalAuthProvider, LoginType } from "react-aad-msal";

var redirectUri = "http://localhost:4000";
var clientId = "2e608cf8-5da2-4a63-a594-9cc9564fe745";

if (process.env.REACT_APP_STAGE === "Dev") {
  redirectUri = "https://app-accounts-request-dev.azurewebsites.net";
}
if (process.env.REACT_APP_STAGE === "Test") {
  redirectUri = "https://app-accounts-request-tst.azurewebsites.net";
} else if (process.env.REACT_APP_STAGE === "Pre-Production") {
  clientId = "b78810af-3b39-4e2e-8c33-da35052e2520";
  redirectUri = "https://hafniabw-account-request-pre.azurewebsites.net";
} else if (process.env.REACT_APP_STAGE === "Production") {
  clientId = "b78810af-3b39-4e2e-8c33-da35052e2520";
  redirectUri = "https://accounts-request.hafniabw.com";
}

const msalConfig = {
  auth: {
    authority:
      "https://login.microsoftonline.com/a364eb28-e95b-4ad0-a4fb-5b4f7767ad84",
    clientId: clientId,
    redirectUri: redirectUri,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: true,
  },
};

const authParameters = {
  scopes: [
    // "api://03099206-65e9-4cbf-877a-e31a9ee8dec5/Read"  // PORTAL SCOPE
    "api://422132b5-d04c-4e43-a6db-6651f01a1109/Read", // API SCOPE
  ],
};

// API
// const msalConfig = {
//   auth: {
//     authority: 'https://login.microsoftonline.com/a364eb28-e95b-4ad0-a4fb-5b4f7767ad84',
//     clientId: '422132b5-d04c-4e43-a6db-6651f01a1109',
//     redirectUri: redirectUri
//   },
//   cache: {
//     cacheLocation: "localStorage",
//     storeAuthStateInCookie: true
//   }
// };

// const authParameters = {
//   scopes: [
//     "api://422132b5-d04c-4e43-a6db-6651f01a1109/Read"
//   ]
// }

const authOptions = {
  loginType: LoginType.Redirect,
  tokenRefreshUri: window.location.origin,
};

export const authProvider = new MsalAuthProvider(
  msalConfig,
  authParameters,
  authOptions
);
