export const RequestHistoryType = {
  lineManager: "Line Manager",
  financeManager: "Finance approver",
  contactEmail: "Contact email",
  contactMobile: "Contact mobile",
};

export const isNullOrEmpty = (value) =>
  value === "" ||
  value === undefined ||
  value === " " ||
  value?.length === 0 ||
  value === null;
