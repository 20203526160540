import React, { useState, useEffect } from "react";
import { v4 as UUID } from "uuid";
import Alerts from "../../molecules/Alerts";

import {
  addAccountRequest,
  financeApproverDataLoader,
  lineManagerDataLoader,
  getAccounts,
  getAccountTypes,
  getAccountTypesFromAccount,
} from "../../../utils/queries";
import "./HomePage.css";
import { EmailSelect } from "../../organisms/EmailSelect";
import AsyncSelect from "react-select/async";
import Select from "react-select";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

import {
  Button,
  FormGroup,
  Container,
  Form,
  Label,
  Input,
  Col,
  Row,
} from "reactstrap";
import { FilesForm } from "./FilesForm";

const Home = () => {
  const [alerts, setAlerts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonText, setButtonText] = useState("Send");

  const [showNewAccountNameError, setShowNewAccountNameError] = useState(false);
  const [showContactNameError, setShowContactNameError] = useState(false);
  const [showEmailError, setShowEmailError] = useState(false);
  const [showContactMobileError, setShowContactMobileError] = useState(false);
  const [accountTypesError, setAccountTypesError] = useState(false);
  const [showBusinessJustificationError, setShowBusinessJustificationError] =
    useState(false);

  const [newAccountName, setNewAccountName] = useState("");
  const [contactName, setContactName] = useState("");
  const [email, setEmail] = useState("");
  const [vendorPassCodeByEmail, setVendorPassCodeByEmail] = useState(false);
  const [contactMobile, setContactMobile] = useState("");
  const [additional, setAdditional] = useState("");
  const [businessJustification, setBusinessJustification] = useState("");
  const [lineManager, setLineManager] = useState(undefined);
  const [showLineManagerError, setShowLineManagerError] = useState(undefined);
  const [financeApprover, setFinanceApprover] = useState(undefined);
  const [showFinanceApproverError, setShowFinanceApproverError] =
    useState(undefined);

  const [selectedAccount, setSelectedAccount] = useState(undefined);
  // const [selectedAccountValue, setSelectedAccountValue] = useState(undefined);

  const [selectedAccountType, setSelectedAccountType] = useState([]);

  const [selectedAccountTypes, setSelectedAccountTypes] = useState([]);
  const [allAccountTypes, setAllAccountTypes] = useState([]);
  const [isLoadingAccountTypes, setIsLoadingAccountTypes] = useState(false);
  const [file, setFile] = useState(null);
  const [fileInput, setFileInput] = useState(null);
  const [optionSelect, setOptionSelect] = useState([]);

  useEffect(() => {
    const bindSelectedAccountTypes = async () => {
      if (selectedAccount && selectedAccount.id) {
        setIsLoadingAccountTypes(true);
        setAllAccountTypes([]);
        let response = await getAccountTypesFromAccount(selectedAccount?.id);
        setSelectedAccountTypes(
          response.data.selectedAccountTypes.map((type) => ({
            label: type.typeName,
            value: type.typeId,
            imoSuffix: type.imoSuffix,
          }))
        );

        setAllAccountTypes(
          response.data.accountTypeLookupLists.map((type) => ({
            label: type.name,
            value: type.id,
            imoSuffix: type.imoSuffix,
          }))
        );
        setIsLoadingAccountTypes(false);
      }
    };
    bindSelectedAccountTypes().then();
  }, [selectedAccount]);

  useEffect(() => {
    const onLoad = async () => {
      await bindAllAccountTypes();
    };
    onLoad().then();
  }, []);

  const handleFinanceApprover = (value) => {
    setFinanceApprover(value);
  };

  const handleLineManager = (value) => {
    setLineManager(value);
  };

  const addAlert = (message, color) => {
    let id = UUID();
    setAlerts([...alerts, { message: message, id: id, color: color }]);
  };

  const validate = () => {
    let isSuccess = true;
    setShowContactNameError(false);
    setShowEmailError(false);
    setShowContactMobileError(false);
    setShowBusinessJustificationError(false);
    setShowLineManagerError(false);
    setShowFinanceApproverError(false);
    setAccountTypesError(false);

    if (!newAccountName && !selectedAccount) {
      setShowNewAccountNameError(true);
      isSuccess = false;
    }

    if (!contactName) {
      setShowContactNameError(true);
      isSuccess = false;
    }

    if (!email) {
      setShowEmailError(true);
      isSuccess = false;
    }

    if (!contactMobile && vendorPassCodeByEmail === false) {
      setShowContactMobileError(true);
      isSuccess = false;
    }

    if (!businessJustification) {
      setShowBusinessJustificationError(true);
      isSuccess = false;
    }

    if (!lineManager) {
      setShowLineManagerError(true);
      isSuccess = false;
    }

    if (!financeApprover) {
      setShowFinanceApproverError(true);
      isSuccess = false;
    }

    if (selectedAccountTypes.length === 0) {
      setAccountTypesError(true);
      isSuccess = false;
    }

    return isSuccess;
  };

  const resetForm = () => {
    // setAccount(null);
    // setSelectedAccount(null);
    setNewAccountName("");
    setContactName("");
    setEmail("");
    setVendorPassCodeByEmail(false);
    setContactMobile("");
    setAdditional("");
    setBusinessJustification("");
    setLineManager(null);
    setFinanceApprover(null);
    setSelectedAccount(null);
    setSelectedAccountTypes([]);
    // setAllAccountTypes(allAccountTypes);
    setAllAccountTypes(optionSelect);
    setFile(null);
    fileInput.value = "";
  };

  const submitted = async (e) => {
    e.preventDefault();

    let validated = validate();

    if (!validated) {
      return;
    }

    try {
      setLoading(true);
      setButtonText("Sending...");

      // console.log(selectedAccount);

      var accountTypes = selectedAccountTypes.map((type) => ({
        accountId: selectedAccount?.id,
        token: selectedAccount?.token,
        typeId: type.value,
        typeName: type.label,
      }));

      await addAccountRequest({
        email: email,
        vendorPassCodeByEmail: vendorPassCodeByEmail,
        contactName: contactName,
        contactMobile:
          contactMobile === "" ||
          contactMobile === undefined ||
          contactMobile === null
            ? null
            : `+${contactMobile}`,
        businessJustification: businessJustification,
        additionalEmail: additional,
        financeApprover: financeApprover,
        lineManager: lineManager,
        accountName: selectedAccount ? selectedAccount.label : null,
        accountId: selectedAccount ? selectedAccount.value : null,
        accountNumber: selectedAccount ? selectedAccount.accountNumber : null,
        token: selectedAccount ? selectedAccount.token : null,
        accountTypeId: selectedAccountType.id,
        accountTypeName: selectedAccountType.name,
        accountTypes: accountTypes,
        newAccountName: newAccountName,
        file: file,
      });

      addAlert(`Request was sent to ${email} successfully`, "success");
      resetForm();
      setLoading(false);
      setButtonText("Send");
    } catch (error) {
      addAlert(error.message, "danger");
      setLoading(false);
      setButtonText("Send");
    }
  };

  const loadAccounts = async (inputValue) => {
    var accounts = await getAccounts(inputValue);

    accounts = accounts.data.map((account) => ({
      ...account,
      label: `${account.name} | ${account.accountNumber} | ${account.shortName} | ${account.countryCode}`,
      value: account.id,
    }));

    return accounts;
  };

  const bindAllAccountTypes = async () => {
    setIsLoadingAccountTypes(true);
    try {
      const types = await getAccountTypes();

      const options = types.data.map((type) => ({
        label: type.name,
        value: type.id,
        imoSuffix: type.imoSuffix,
      }));
      setAllAccountTypes(options);
      setOptionSelect(options);
    } finally {
      setIsLoadingAccountTypes(false);
    }
  };

  const onAccountChanged = async (account) => {
    if (!account) {
      setSelectedAccount(null);
      setSelectedAccountTypes([]);
      //setAllAccountTypes([]);

      return;
    }

    // console.log("onAccountChanged");
    // console.log(account);
    setSelectedAccount(account);

    // console.log("onAccountChanged");
    // console.log(selectedAccount);
  };

  const onAccountInputChanged = (val) => {
    // console.log("Input changed!", val);
  };

  const NoOptionsMessage = (props) => {
    return (
      <div style={{ textAlign: "center", color: "grey" }}>
        No data found. Start typing in searchbox to find required data.
      </div>
    );
  };

  const handleAccountTypeOnChange = (options) => {
    let tempOptions = [...optionSelect];
    if (options.length > 0) {
      options.map((option) => {
        if (option.imoSuffix !== null) {
          tempOptions = tempOptions.filter((opt) => opt.imoSuffix === null);
        }
        tempOptions = tempOptions.filter((opt) => opt !== option);
      });
    }
    setAllAccountTypes(tempOptions);
    setSelectedAccountTypes(options);
  };

  return (
    <Container>
      <Row>
        <Col>
          <Alerts alerts={alerts} setAlerts={setAlerts} />
        </Col>
      </Row>

      <Form onSubmit={submitted}>
        <FormGroup>
          <Label for="newAccountName">New account name</Label>
          <Input
            type="text"
            name="newAccountName"
            id="newAccountName"
            placeholder="New account name"
            onChange={(e) => setNewAccountName(e.target.value)}
            value={newAccountName}
          />
          {showNewAccountNameError && <p className="text-danger">Required</p>}
        </FormGroup>

        <FormGroup>
          <Label for="exampleEmail">Select existing account</Label>
          <AsyncSelect
            cacheOptions
            // getOptionLabel={(e) => e.status}
            // getOptionValue={(e) => e.id}
            loadOptions={loadAccounts}
            onChange={(p) => onAccountChanged(p)}
            onInputChange={(p) => onAccountInputChanged(p)}
            placeholder="Search for accounts"
            components={{ NoOptionsMessage }}
            value={selectedAccount}
            isClearable
          />
        </FormGroup>

        <FormGroup>
          <Label for="accountTypes">Account types</Label>
          <Select
            placeholder="First select account above"
            isMulti
            cacheOptions
            isLoading={isLoadingAccountTypes}
            value={selectedAccountTypes}
            onChange={(e) => {
              if (!e) {
                setSelectedAccountTypes([]);
                setAllAccountTypes(optionSelect);
              } else {
                handleAccountTypeOnChange(e);
              }
            }}
            options={allAccountTypes}
          />
          {accountTypesError && <p className="text-danger">Required</p>}
        </FormGroup>

        <FormGroup>
          <Label for="contactName">Contact name</Label>
          <Input
            type="text"
            name="contactName"
            id="contactName"
            placeholder="Contact name"
            onChange={(e) => setContactName(e.target.value)}
            value={contactName}
          />
          {showContactNameError && <p className="text-danger">Required</p>}
        </FormGroup>

        <FormGroup>
          <Label for="email">Contact email</Label>
          <Input
            type="email"
            name="email"
            id="email"
            placeholder="Contact email"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
          />
          {showEmailError && <p className="text-danger">Required</p>}
        </FormGroup>

        <FormGroup className="form-control-check">
          <Label check>
            <Input
              type="checkbox"
              checked={vendorPassCodeByEmail}
              onChange={() => {
                setVendorPassCodeByEmail(!vendorPassCodeByEmail);
              }}
            />{" "}
            Vendor can only use email
          </Label>
        </FormGroup>

        <FormGroup>
          <Label for="contactMobile">Contact mobile</Label>
          <PhoneInput
            placeholder="Contact mobile"
            value={contactMobile}
            inputClass="form-control"
            inputStyle={{
              width: "100%",
            }}
            onChange={setContactMobile}
          />
          {showContactMobileError && <p className="text-danger">Required</p>}
        </FormGroup>

        <FormGroup>
          <Label for="exampleEmail">Additional email text</Label>
          <Input
            type="textarea"
            name="additionalEmail"
            id="additionalEmail"
            placeholder="Additional email text that will be included in email"
            onChange={(e) => setAdditional(e.target.value)}
            value={additional}
          />
        </FormGroup>

        <FormGroup>
          <Label for="exampleEmail">Business justification</Label>
          <Input
            type="textarea"
            name="businessJustification"
            id="businessJustification"
            placeholder="Business justification for request"
            onChange={(e) => setBusinessJustification(e.target.value)}
            value={businessJustification}
          />
          {showBusinessJustificationError && (
            <p className="text-danger">Required</p>
          )}
        </FormGroup>

        <FormGroup>
          <Label className="w-100">Line manager to be notified</Label>
          <EmailSelect
            value={lineManager}
            onChange={handleLineManager}
            xPageForm
            className="react-select"
            dataLoader={lineManagerDataLoader}
          />
          {showLineManagerError && <p className="text-danger">Required</p>}
        </FormGroup>

        <FormGroup>
          <Label className="w-100">Finance to be notified</Label>
          <EmailSelect
            value={financeApprover}
            onChange={handleFinanceApprover}
            xPageForm
            className="react-select"
            dataLoader={financeApproverDataLoader}
          />
          {showFinanceApproverError && <p className="text-danger">Required</p>}
        </FormGroup>

        <FormGroup>
          <Label>Attach doc as additional business justification.</Label>
          <FilesForm
            file={file}
            setFile={setFile}
            setFileInput={setFileInput}
          ></FilesForm>
        </FormGroup>

        <Button style={{ marginTop: 10 }} disabled={loading}>
          {buttonText}
        </Button>
      </Form>
    </Container>
  );
};

export default Home;
