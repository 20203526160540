import React, { useState, useEffect, useCallback } from "react";
import { v4 } from "uuid";
import Alerts from "../../molecules/Alerts";
import moment from "moment";
import "./History.css";
import { Container, Input, Col, Row, Table, Button } from "reactstrap";
import {
  financeApproverDataLoader,
  getMyAccounts,
  lineManagerDataLoader,
} from "../../../utils/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell, faList, faRedo } from "@fortawesome/free-solid-svg-icons";
import { apiSendRemainder } from "../../../utils/queries";
import { trackPromise } from "react-promise-tracker";
import { Link, Route, useHistory, useParams } from "react-router-dom";
import { AccountModal } from "../AccountModal/AccountModal";
import { isNil } from "ramda";
import { ValidationModal } from "./ValidationModal";
import { ChangeApproverModel } from "../ChangeApproverModel/ChangeApproverModel";

const History = (props) => {
  const history = useHistory();
  const [alerts, setAlerts] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [defaultValue, setDefaultValue] = useState("");
  const [accountList, setAccountList] = useState([]);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [statusList, setStatusList] = useState([]);
  const [toggleAccountModal, setToggleAccountModal] = useState(false);
  const [toggleChangeApproverModel, setToggleChangeApproverModel] =
    useState(false);
  const [changeApproverDataLoader, setChangeApproverDataLoader] =
    useState(null);
  const [changeApproverType, setChangeApproverType] = useState("");
  const [toggleValidationModal, setToggleValidationModalInternal] =
    useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const setToggleValidationModal = (value) => {
    setToggleValidationModalInternal(value);
    if (value === false) history.push("/history");
  };

  const addAlert = useCallback(
    (message, color) => {
      let id = v4();
      setAlerts([...alerts, { message: message, id: id, color: color }]);
    },
    [setAlerts, alerts]
  );

  const searchChanged = (e) => {
    setNameFilter(e.target.value);
  };

  useEffect(() => {
    const loadAccounts = async () => {
      try {
        const result = await trackPromise(getMyAccounts());
        let statusDistinct = result.data.map((s) => s.status);
        statusDistinct = [...new Set(statusDistinct)];
        statusDistinct = statusDistinct.filter((value) => value !== null);
        statusDistinct = statusDistinct.sort((a, b) => a.localeCompare(b));
        statusDistinct.unshift("Select status");

        setStatusList(statusDistinct);

        setAccountList(result.data);
      } catch (error) {
        addAlert(error, "danger");
      }
    };
    loadAccounts().then();
  }, [addAlert]);

  async function sendReminder(id) {
    try {
      const result = await apiSendRemainder(id);
      const account = result.data;
      setAccountList(
        accountList.map((a) => {
          if (a.id === id) a.sendReminderDate = account.sendReminderDate;
          return a;
        })
      );
      addAlert("Reminder sent successfully", "success");
    } catch (error) {
      let message = error.response.data.message;
      message = message ? message : "Unknown error occurred";
      addAlert(message, "danger");
    }
  }

  const statusChanged = async (e) => {
    setSelectedStatus(e.target.value);
  };

  const showAccountModal = (account) => {
    setSelectedAccount(account);
    setToggleAccountModal(true);
  };

  const showChangeApproverModal = (account, type, dataLoader, val) => {
    setSelectedAccount(account);
    setToggleChangeApproverModel(true);
    setChangeApproverType(type);
    setChangeApproverDataLoader(dataLoader);
    setDefaultValue(val);
  };

  const onUpdateAccount = (response) => {
    setAccountList(
      accountList.map((el) => (el.id === response.data.id ? response.data : el))
    );
  };

  function canChangeLineManager(account) {
    return (
      isNil(account.approvedByLineManagerEmail) &&
      account.status !== "Rejected by line manager" &&
      account.status !== "Cancelled" &&
      account.status !== "Completed"
    );
  }

  function canChangeFinance(account) {
    return (
      isNil(account.approvedByFinanceEmail) &&
      isNil(account.formResubmittedByEmail) &&
      account.status !== "Rejected by finance" &&
      account.status !== "Cancelled" &&
      account.status !== "Completed" &&
      account.status !== "Form resubmitted"
    );
  }
  const filterLogic = (account) => {
    let searchValue = parseInt(nameFilter, 10);
    if (isNaN(searchValue)) {
      return (
        (!nameFilter ||
          account?.name?.toLowerCase().includes(nameFilter.toLowerCase()) ||
          account?.accountName
            ?.toLowerCase()
            .includes(nameFilter.toLowerCase()) ||
          account?.newAccountName
            ?.toLowerCase()
            .includes(nameFilter.toLowerCase()) ||
          account?.token?.toLowerCase() === nameFilter.toLowerCase()) &&
        (!selectedStatus ||
          selectedStatus === "Select status" ||
          account.status === selectedStatus)
      );
    }
    return (
      Object.values(account).some((a) => String(a).includes(searchValue)) &&
      (!selectedStatus ||
        selectedStatus === "Select status" ||
        account.status === selectedStatus)
    );
  };

  return (
    <>
      <Container>
        {/* <div>
        <pre className="pre">{JSON.stringify(selectedAccount, null, 2)}</pre>
      </div> */}

        <Row>
          <Col>
            <Alerts alerts={alerts} setAlerts={setAlerts} />
          </Col>
        </Row>

        <Row style={{ marginBottom: 10 }}>
          <Col>
            <Input
              type="search"
              name="search"
              id="search"
              placeholder="Enter name (minimum 2 characters) or account Id"
              onChange={searchChanged}
            />
          </Col>
          <Col>
            <select
              className="form-control"
              name="status"
              onChange={(e) => statusChanged(e)}
            >
              {statusList.map((status, index) => (
                <option key={index} value={status}>
                  {status}
                </option>
              ))}
            </select>
          </Col>
        </Row>
      </Container>
      <Container fluid>
        <Row>
          <Col>
            <Table bordered striped>
              <thead>
                <tr>
                  <th>Id</th>
                  <th>Vendor bank details verbally confirmed</th>
                  <th>Existing account number</th>
                  <th>Existing account</th>
                  <th>New account name</th>
                  <th>Vendor name</th>
                  <th>Contact email</th>
                  <th>Contact mobile</th>
                  <th>Line manager</th>
                  <th>Finance</th>
                  <th>Status</th>
                  <th>Submitted</th>
                  <th>Cancelled</th>
                  <th>Send reminder</th>
                  <th>Validate</th>
                </tr>
              </thead>
              <tbody>
                {accountList?.filter(filterLogic).map((account, index) => (
                  <tr key={account.id}>
                    <td>{account.id}</td>
                    <td>{account.verballyConfirmed ? "Yes" : "No"}</td>
                    <td>{account.accountNumber}</td>
                    <td>{account.accountName}</td>
                    <td>{account.newAccountName}</td>
                    <td>{account.name}</td>
                    <td>
                      <span className="text-break">
                        {account.requestContactEmail}
                      </span>
                      {canChangeLineManager(account) ? (
                        <Button
                          className="ml-2"
                          color={null}
                          size="sm"
                          onClick={() => {
                            showChangeApproverModal(
                              account,
                              "Contact email",
                              null,
                              account.requestContactEmail
                            );
                          }}
                        >
                          <FontAwesomeIcon icon={faRedo} />
                        </Button>
                      ) : null}
                    </td>
                    <td>
                      <>
                        <span>{account.requestContactMobile}</span>
                        {canChangeLineManager(account) ? (
                          <Button
                            className="ml-2"
                            color={null}
                            size="sm"
                            onClick={() => {
                              showChangeApproverModal(
                                account,
                                "Contact mobile",
                                null,
                                account.requestContactMobile
                              );
                            }}
                          >
                            <FontAwesomeIcon icon={faRedo} />
                          </Button>
                        ) : null}
                      </>
                    </td>
                    <td>
                      <>
                        <span className="text-break">
                          {account.lineManagerToNotify}
                        </span>
                        {canChangeLineManager(account) ? (
                          <Button
                            className="ml-2"
                            color={null}
                            size="sm"
                            onClick={() => {
                              showChangeApproverModal(
                                account,
                                "Line manager",
                                lineManagerDataLoader,
                                ""
                              );
                            }}
                          >
                            <FontAwesomeIcon icon={faRedo} />
                          </Button>
                        ) : null}
                      </>
                    </td>
                    <td>
                      <>
                        <span className="text-break">
                          {account.financeApproverToNotify}
                        </span>
                        {canChangeFinance(account) ? (
                          <Button
                            className="ml-2"
                            color={null}
                            size="sm"
                            onClick={() => {
                              showChangeApproverModal(
                                account,
                                "Finance approver",
                                financeApproverDataLoader,
                                ""
                              );
                            }}
                          >
                            <FontAwesomeIcon icon={faRedo} />
                          </Button>
                        ) : null}
                      </>
                    </td>
                    <td>{account.status}</td>
                    <td>
                      {account.formSubmittedDate == null
                        ? ""
                        : moment(account.formSubmittedDate).format(
                            "DD-MM-YYYY HH:mm"
                          )}
                    </td>
                    <td style={{ width: 70 }} className={"text-center"}>
                      {(() => {
                        if (account.status === "Completed") {
                          return <div></div>;
                        } else if (!account.cancelled === true) {
                          return (
                            <button
                              className="btn btn-link p-0"
                              onClick={() => showAccountModal(account)}
                            >
                              Click to Cancel
                            </button>
                          );
                        } else {
                          return <div>Is Cancelled</div>;
                        }
                      })()}
                    </td>
                    <td style={{ width: 150 }} className={"text-center"}>
                      {account.status === "Form requested" ||
                      account.status === "Send back by requestor" ? (
                        <>
                          <Button
                            className="ml-2"
                            color={null}
                            size="sm"
                            onClick={() => sendReminder(account.id)}
                          >
                            <FontAwesomeIcon icon={faBell} />
                          </Button>
                          {isNil(account.sendReminderDate) ? (
                            <label>Not sent</label>
                          ) : (
                            <label>
                              {moment(account.sendReminderDate).format(
                                "DD-MM-YYYY HH:mm"
                              )}
                            </label>
                          )}
                        </>
                      ) : null}
                    </td>
                    <td style={{ width: 100 }} className={"text-center"}>
                      <Link
                        className="btn btn-sm"
                        to={`${props.match.url}/edit/${account.id}`}
                      >
                        <FontAwesomeIcon icon={faList} />
                      </Link>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Col>
        </Row>

        <AccountModal
          setToggle={setToggleAccountModal}
          toggle={toggleAccountModal}
          account={selectedAccount}
          onUpdateAccount={onUpdateAccount}
          // onSendToMDM={onSendToMDM}
        />
        <ChangeApproverModel
          account={selectedAccount}
          onUpdateAccount={onUpdateAccount}
          setToggleModal={setToggleChangeApproverModel}
          toggleModal={toggleChangeApproverModel}
          type={changeApproverType}
          dataLoader={changeApproverDataLoader}
          defaultValue={defaultValue}
        />
      </Container>

      <Route path={`${props.match.url}/edit/:id`} exact>
        <ShowValidationModal
          // addAlert={addAlert}
          setToggleAccountModal={setToggleValidationModal}
          toggleAccountModal={toggleValidationModal}
          setShowLoader={setShowLoader}
          //onApprove={onApprove}
          onUpdateAccount={onUpdateAccount}
          // onSendToMDM={onSendToMDM}
        />
      </Route>
    </>
  );
};

const ShowValidationModal = ({
  setToggleAccountModal,
  toggleAccountModal,
  addAlert,
  onApprove,
  onReject,
  onUpdateAccount,
  // onSendToMDM,
  setShowLoader,
}) => {
  const { id } = useParams();

  return (
    <ValidationModal
      id={id}
      toggleValue={toggleAccountModal}
      setToggle={setToggleAccountModal}
      addAlert={addAlert}
      // onApprove={onApprove}
      // onReject={onReject}
      setShowLoader={setShowLoader}
      onUpdateAccount={onUpdateAccount}
      // onSendToMDM={onSendToMDM}
    />
  );
};

export default History;
