import React from "react";
import { Nav } from "reactstrap";
import { Route } from "react-router-dom";
import { Link } from "react-router-dom";
import Logo from "../../assets/hafnia-logo.png";
import { authProvider } from "../../authProvider";

const Header = () => {
  const logout = async () => {
    authProvider.logout();
  };

  return (
    <div className="d-flex flex-column flex-md-row align-items-center p-3 px-md-4 mb-3 bg-white border-bottom box-shadow">
      <div className="navbar-left my-0 mr-md-auto">
        <img height="46" src={Logo} alt="" />
        <span
          style={{
            marginLeft: "20px",
            fontSize: "20px",
            verticalAlign: "middle",
          }}
        >
          {process.env.REACT_APP_STAGE}
        </span>
      </div>
      <Nav className="my-2 my-md-0 mr-md-3">
        <Link to="/request" className={"p-2  topmenu"}>
          Request
        </Link>
        <Link to="/history" className={"p-2  topmenu"}>
          History
        </Link>
        {/* <Link to="/accountsearch" className={"p-2  topmenu"}>
          Account Search
        </Link> */}
        <Link onClick={logout} to="" className={"p-2  topmenu"}>
          Logout ({authProvider.account?.userName})
        </Link>
      </Nav>
    </div>
  );
};

const RoutedHeader = () => (
  <Route path={["/page1/:token?", "/:token?"]} component={Header} />
);
export default RoutedHeader;
