import React, { useState, useEffect } from "react";
import { v4 } from "uuid";
import Alerts from "../../molecules/Alerts";
import moment from "moment";
import "./AccountSearch.css";
import { Container, Input, Col, Row, Table, Button } from "reactstrap";
import { getAccounts } from "../../../utils/queries";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { apiSendRemainder } from "../../../utils/queries";
import { trackPromise } from "react-promise-tracker";

const AccountSearch = (props) => {
  const [alerts, setAlerts] = useState([]);
  const [nameFilter, setNameFilter] = useState("");
  const [accountList, setAccountList] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [statusList, setStatusList] = useState([]);
  const [searchedAccountList, setSearchedAccountList] = useState([]);
  const [query, setQuery] = useState("");

  useEffect(() => {
    loadAccounts().then();
  }, []);

  // const onSearch = async (e) => {
  //   const result = await trackPromise(getSearchedAccounts(query));
  //   setSearchedAccountList(result.data);
  // };

  const addAlert = (message, color) => {
    let id = v4();
    setAlerts([...alerts, { message: message, id: id, color: color }]);
  };

  const searchChanged = (e) => {
    setNameFilter(e.target.value);
  };

  const loadAccounts = async () => {
    try {
      const result = await trackPromise(getAccounts());
      // let statusDistinct = result.data.map((s) => s.status);
      // statusDistinct = [...new Set(statusDistinct)];
      // statusDistinct = statusDistinct.filter(value => value !== null);
      // statusDistinct = statusDistinct.sort((a, b) => a.localeCompare(b));
      // statusDistinct.unshift("Select status");

      //setStatusList(statusDistinct);

      setAccountList(result.data);
    } catch (error) {
      addAlert(error, "danger");
    }
  };

  // async function sendReminder(id) {
  //   try {
  //     await trackPromise(apiSendRemainder(id));
  //     addAlert("Reminder sent successfully", "success");
  //   } catch (error) {
  //     let message = error.response.data.message;
  //     message = message ? message : "Unknown error occurred"
  //     addAlert(message, "danger");
  //   }
  // }

  // const statusChanged = async (e) => {
  //   setSelectedStatus(e.target.value);
  // };

  const filterLogic = (account) => {
    let searchValue = parseInt(nameFilter, 10);
    if (isNaN(searchValue)) {
      return (
        (!nameFilter ||
          account?.name?.toLowerCase().includes(nameFilter.toLowerCase()) ||
          account?.accountName
            ?.toLowerCase()
            .includes(nameFilter.toLowerCase()) ||
          account?.newAccountName
            ?.toLowerCase()
            .includes(nameFilter.toLowerCase()) ||
          account?.token?.toLowerCase() === nameFilter.toLowerCase()) &&
        (!selectedStatus ||
          selectedStatus === "Select status" ||
          account.status === selectedStatus)
      );
    }
    return (
      Object.values(account).some((a) => String(a).includes(searchValue)) &&
      (!selectedStatus ||
        selectedStatus === "Select status" ||
        account.status === selectedStatus)
    );
  };

  return (
    <Container>
      <Row>
        <Col>
          <Alerts alerts={alerts} setAlerts={setAlerts} />
        </Col>
      </Row>

      <Row style={{ marginBottom: 10 }}>
        {/* <Col xs="1">
      <Button onClick={onSearch}>Search</Button>
      </Col> */}
        <Col>
          <Input
            type="search"
            name="search"
            id="search"
            placeholder="Enter name (minimum 2 characters)"
            onChange={searchChanged}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          {searchedAccountList.map((name) => (
            <li key={name}>{name}</li>
          ))}
        </Col>
      </Row>

      <Row>
        <Col>
          <Table bordered striped>
            <thead>
              <tr>
                <th>Account</th>
                <th>Address1</th>
                <th>City</th>
                <th>Country</th>
                {/* <th> </th> */}
              </tr>
            </thead>
            <tbody>
              {accountList?.filter(filterLogic).map((account, index) => (
                <tr key={account.id}>
                  <td>{account.name}</td>
                  <td>{account.address1Line1}</td>
                  <td>{account.address1City1}</td>
                  <td>{account.country}</td>
                  {/* <td style={{ width: 70 }} className={"text-center"}>
                      {account.status === "Form requested" ? (
                        <Button
                          className="ml-2"
                          color={null}
                          size="sm"
                          onClick={() => sendReminder(account.id)}
                        >
                          <FontAwesomeIcon icon={faBell} />
                        </Button>
                      ) : null}
                    </td> */}
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </Container>
  );
};

export default AccountSearch;
