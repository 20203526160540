const local = {
  API_URL: "https://localhost:44300/api/",
};

const dev = {
  API_URL: "https://app-accounts-private-api-dev.azurewebsites.net/api/",
};

const test = {
  API_URL: "https://app-accounts-private-api-tst.azurewebsites.net/api/",
};

const preProd = {
  API_URL: "https://hafniabw-account-api-pre.azurewebsites.net/api/",
};

const prod = {
  API_URL: "https://accounts-private-api.hafniabw.com/api/",
};

var config = local;

if (process.env.REACT_APP_STAGE === "Dev") {
  config = dev;
}

if (process.env.REACT_APP_STAGE === "Production") {
  config = prod;
} else if (process.env.REACT_APP_STAGE === "Pre-Production") {
  config = preProd;
} else if (process.env.REACT_APP_STAGE === "Test") {
  config = test;
}

export default {
  // Add common config values here
  MAX_ATTACHMENT_SIZE: 5000000,
  ...config,
};
