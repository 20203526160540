import React, { useEffect, useState } from "react";
import { cancelRequest } from "../../../utils/queries";

import {
  Button,
  Form,
  Label,
  Input,
  FormGroup,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";

import Alerts from "../../molecules/Alerts";
import v4 from "uuid/dist/v4";

export const AccountModal = (props) => {
  const [alerts, setAlerts] = useState([]);

  const [showAdditionalError, setShowAdditionalError] = useState(false);
  const [justification, setJustification] = useState("");

  useEffect(() => {
    setJustification(props.account?.cancelledByReason);
  }, [props.account]);

  const addAlert = (message, color) => {
    let id = v4();
    setAlerts([...alerts, { message: message, id: id, color: color }]);
  };

  const toggle = () => {
    props.setToggle(!props.toggle);

    setJustification(props.account.cancelledByReason);
  };

  const submitted = async (e) => {
    e.preventDefault();

    let validated = validate();

    if (!validated) {
      return;
    }

    try {
      let account = await cancelRequest(props.account.id, justification);

      props.onUpdateAccount(account);
    } catch (error) {
      addAlert("Could not cancel request. Please contact IT.", "danger");
    }

    toggle();
  };

  const validate = () => {
    let isSuccess = true;

    if (!justification) {
      setShowAdditionalError(true);
      isSuccess = false;
    }

    return isSuccess;
  };

  return props.account ? (
    <div>
      <Modal isOpen={props.toggle} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>Cancel Request</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Alerts alerts={alerts} setAlerts={setAlerts} />
            </Col>
          </Row>
          <div style={{ marginBottom: 10 }}>
            <Form onSubmit={submitted}>
              <FormGroup>
                <Label for="exampleEmail">Justification</Label>
                <Input
                  type="textarea"
                  name="justification"
                  id="justification"
                  placeholder="Justification for cancelling request required"
                  onChange={(e) => setJustification(e.target?.value)}
                  value={justification || ""}
                  disabled={props.account.cancelled === true}
                />
                {showAdditionalError && <p className="text-danger">Required</p>}
              </FormGroup>

              <Button
                color="success"
                className="mr-2 mt-3"
                disabled={props.account.cancelled === true}
              >
                Mark as cancelled
              </Button>
            </Form>
          </div>

          <div></div>
        </ModalBody>
      </Modal>
    </div>
  ) : null;
};
