import axios from "axios";
import config from "../config";
import { isNil } from "ramda";

const apiGet = async (url, token) => {
  return axios.get(`${config.API_URL}${url}`);
};

const apiPost = async (url, form) => {
  return axios.post(`${config.API_URL}${url}`, form);
};

export const getAllWithAccountNumber = async () => {
  const url = `account/GetWithAccountNumber`;
  return apiGet(url);
};

export const addAccountRequest = async (accountRequest) => {
  const url = `request`;
  const data = new window.FormData();
  data.append("accountId", accountRequest.accountId || "");
  data.append("accountName", accountRequest.accountName || "");
  data.append("accountNumber", accountRequest.accountNumber || "");
  accountRequest.accountTypes.forEach((accountType, index) => {
    Object.keys(accountType).forEach((key) => {
      if (!isNil(accountType[key])) {
        data.append(`accountTypes[${index}].${key}[]`, accountType[key]);
      }
    });
  });
  data.append("vendorPassCodeByEmail", accountRequest.vendorPassCodeByEmail);
  data.append("additionalEmail", accountRequest.additionalEmail);
  data.append("businessJustification", accountRequest.businessJustification);
  if (accountRequest.contactMobile !== null)
    data.append("contactMobile", accountRequest.contactMobile);
  data.append("contactName", accountRequest.contactName);
  data.append("email", accountRequest.email);
  data.append("financeApprover", accountRequest.financeApprover);
  data.append("lineManager", accountRequest.lineManager);
  data.append("newAccountName", accountRequest.newAccountName);
  data.append("file", accountRequest.file);
  return axios.post(`${config.API_URL}${url}`, data, {
    headers: { "Content-Type": "multipart/form-data" },
  });
};

export const getMyAccounts = () => apiGet("account/my");
const getFinanceApproverUsers = () => apiGet("user/FinanceApprover");
export const financeApproverDataLoader = {
  loader: getFinanceApproverUsers,
  uniqueKey: "getFinanceApproverUsers",
};
const getLineManagerUsers = () => apiGet("user/LineManager");
export const lineManagerDataLoader = {
  loader: getLineManagerUsers,
  uniqueKey: "getLineManagerUsers",
};

export const getAccounts = async (q) =>
  apiGet(`AccountSearch/GetAccounts/${q}`);

//   export const getAccountTypesFromAccount = async (accountId) =>
//   apiGet(`AccountSearch/GetAccountTypesFromAccount/${accountId}`);

// export const getAccountTypes = async (q) =>
//   apiGet(`AccountSearch/GetAccountTypes`);

export const getAccountTypesFromAccount = async (accountId) =>
  apiGet(`AccountSearch/GetAllAccountTypesFromAccount/${accountId}`);

export const getAccountTypes = async (q) =>
  apiGet(`AccountSearch/GetAllAccountTypes`);

export const apiSendRemainder = async (id) => {
  return apiPost(`account/sendreminder`, { id: id });
};

export const cancelRequest = async (id, cancelReason) => {
  const data = {
    VFAccountId: parseInt(id, 10),
    rejectReason: cancelReason,
  };

  console.log(data);

  return apiPost(`account/CancelRequest/`, data);
};

export const sendBankToVendor = async (id, additionalEmailText) => {
  const data = {
    VFAccountId: parseInt(id, 10),
    AdditionalEmail: additionalEmailText,
  };
  return apiPost(`account/sendBackToVendor`, data);
};

export const reopenForm = async (id, reason) => {
  const data = {
    VFAccountId: parseInt(id, 10),
    Reason: reason,
  };
  return apiPost(`account/reopen`, data);
};

export const getAccount = async (id) => {
  const url = `account/${id}`;
  return apiGet(url);
};

export const validateRequestor = async (id) => {
  const url = `account/ValidateRequestor`;
  return apiPost(url, {
    VFAccountId: id,
  });
};

export const changeLineManagerApprover = async (id, email) => {
  const url = `account/ChangeLineManager`;
  return apiPost(url, {
    Id: id,
    ApproverEmail: email,
  });
};

export const changeFinanceApprover = async (id, email) => {
  const url = `account/ChangeFinanceApprover`;
  return apiPost(url, {
    Id: id,
    ApproverEmail: email,
  });
};

export const changeContactEmail = async (id, email) => {
  const url = `account/ChangeContactEmail`;
  return apiPost(url, {
    Id: id,
    ContactEmail: email,
  });
};

export const changeContactMobile = async (id, mobile) => {
  const url = `account/ChangeContactMobile`;
  return apiPost(url, {
    Id: id,
    ContactMobile: mobile,
  });
};
