import React from "react";
import Header from "./components/organisms/Header";
import Main from "./components/organisms/Main";
import {useParams} from "react-router";
import {QueryClient, QueryClientProvider} from 'react-query'

const queryClient = new QueryClient()

const App = (props) => {
    let {token} = useParams();


    return (
        <QueryClientProvider client={queryClient}>
            <div>
                <Header token={token}/>
                <Main/>
            </div>
        </QueryClientProvider>
    )
};

export default App;
