import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faPlus
} from "@fortawesome/free-solid-svg-icons";

import {
    Col,
    Row,
    Label,
} from "reactstrap";

export const FilesForm = ({setFile, file, setFileInput}) => {

    const selectedFilesChanged = async (e) => {
        const file = e.target.files[0];
        setFile(file);        
    };

    return (
        <>
            <Row>
                <Col Col xs={1}>
                    <Label for="file-upload-docs" className={"btn btn-info"}>
                        <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
                    </Label>
                    <input
                        id="file-upload-docs"
                        type="file"
                        onChange={selectedFilesChanged}
                        ref={ref=> setFileInput(ref)}
                    />
                </Col>
                <Col className='align-self-xl-center'>{file?.name}</Col>
            </Row>
        </>
    );
};
