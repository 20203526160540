import React, { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

export const ReopenModal = ({ toggle, setToggle, onSubmit, accountId }) => {
  const [reason, setReason] = useState("");
  const [showAdditionalError, setShowAdditionalError] = useState(false);

  const triggerToggle = () => {
    setToggle(!toggle);
  };

  const submitted = async (e) => {
    e.preventDefault();

    let validated = validate();

    if (!validated) {
      return;
    }

    triggerToggle();
    onSubmit(accountId, reason);
  };

  const validate = () => {
    let isSuccess = true;

    if (!reason) {
      setShowAdditionalError(true);
      isSuccess = false;
    }

    return isSuccess;
  };

  const reset = () => {
    setReason("");
    setShowAdditionalError(false);
  };

  return (
    <Modal isOpen={toggle} toggle={triggerToggle} size="lg">
      <ModalHeader toggle={triggerToggle}>Reopen Account</ModalHeader>
      <ModalBody>
        <Form onSubmit={submitted}>
          <FormGroup>
            <Label for="additionalEmail">Justification</Label>
            <Input
              type="textarea"
              name="additionalEmail"
              id="additionalEmail"
              placeholder="Justification text that will be included in email"
              onChange={(e) => setReason(e.target?.value)}
              value={reason}
            />
            {showAdditionalError && <p className="text-danger">Required</p>}
          </FormGroup>

          <Button color="success" className="mr-2 mt-3">
            Reopen
          </Button>
          <Button
            className="mt-3"
            color="danger"
            onClick={() => {
              triggerToggle();
              reset();
            }}
          >
            Cancel
          </Button>
        </Form>
      </ModalBody>
    </Modal>
  );
};
