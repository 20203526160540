import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Home from "../pages/HomePage/HomePage";
import { AzureAD } from "react-aad-msal";
import { authProvider } from "../../authProvider";
import History from "../pages/History/History";
import AccountSearch from "../pages/AccountSearch/AccountSearch";

const Main = () => (
  <div>
    <main>
      <Switch>
        <AzureAD provider={authProvider} forceLogin={true}>
          <Route path="/history" component={History} />
          <Route path="/accountsearch" component={AccountSearch} />
          <Route path="/request" component={Home} />
          <Route exact path="/">
            <Redirect to="/request" />
          </Route>
        </AzureAD>
      </Switch>
    </main>
  </div>
);

export default Main;
