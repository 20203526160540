import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Table,
} from "reactstrap";
import Alerts from "../../molecules/Alerts";
import { v4 } from "uuid";
import {
  getAccount,
  reopenForm,
  sendBankToVendor,
  validateRequestor,
} from "../../../utils/queries";
import { SendBackToVendorModal } from "./SendBackToVendorModal";
import { AccountModal } from "../AccountModal/AccountModal";
import { ReopenModal } from "./ReopenModal";
import { trackPromise } from "react-promise-tracker";

export const ValidationModal = ({
  setToggle,
  toggleValue,
  id,
  setShowLoader,
  onUpdateAccount,
}) => {
  const [account, setAccount] = useState(null);
  const [toggleAccountModal, setToggleAccountModal] = useState(false);
  const [formConfirmed, setFormConfirmed] = useState(false);
  const [accountTypes, setAccountTypes] = useState("");
  const [banks, setBanks] = useState(null);
  const [files, setFiles] = useState(null);
  const [toggleSendBackToVendorModal, setToggleSendBackToVendorModal] =
    useState(false);
  const [toggleReopenFormModal, setToggleReopenFormModal] = useState(false);
  const [alerts, setAlerts] = useState([]);

  const toggle = () => {
    setToggle(!toggleValue);
  };

  const onSendBackToVendor = async (accountId, additionalEmailText) => {
    try {
      let result = await sendBankToVendor(accountId, additionalEmailText);
      setAccount(result.data);
      onUpdateAccount(result);
      addAlert("Successfully resubmitted the request");
    } catch (error) {
      console.log(error);
      addAlert(error, "danger");
    }
  };

  const onValidate = async (accountId) => {
    try {
      let result = await validateRequestor(accountId);
      setAccount(result.data);
      addAlert("Successfully validated the request");
    } catch (error) {
      console.log(error);
      addAlert(error, "danger");
    }
  };

  const addAlert = (message, color) => {
    let id = v4();
    setAlerts([...alerts, { message: message, id: id, color: color }]);
  };

  const showAccountModal = async () => {
    try {
      const result = await trackPromise(getAccount(id));
      if (result.data.account.verballyConfirmed) {
        setFormConfirmed(true);
      }
      setAccount(result.data.account);
      setAccountTypes(result.data.accountTypes);
      setBanks(result.data.bankDataList);
      setFiles(result.data.files);
    } catch (error) {
      addAlert(error, "danger");
    }
  };

  const approvalEnabled = () => {
    return account.status === "Form submitted";
  };

  const canReopen = () => {
    return account.status === "Rejected by line manager";
  };

  const onReopen = async (accountId, reason) => {
    try {
      const result = await reopenForm(accountId, reason);
      onUpdateAccount(result);
      setAccount(result.data.account);
      setAccountTypes(result.data.accountTypes);
      setBanks(result.data.bankDataList);
      setFiles(result.data.files);
    } catch (error) {
      addAlert(error, "danger");
    }
  };

  const cancelEnabled = () => {
    return (
      account.status !== "Cancelled" &&
      account.status !== "Validated by requestor"
    );
  };

  useEffect(() => {
    async function run() {
      await showAccountModal().then();
      toggle();
    }

    run().then();
  }, [id]);

  return account ? (
    <div>
      <Modal isOpen={toggleValue} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>
          Account Info (ID: {account.id})
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Alerts alerts={alerts} setAlerts={setAlerts} />
            </Col>
          </Row>
          <FormGroup check>
            <Label check>
              <Input
                type="checkbox"
                checked={formConfirmed}
                disabled={account.verballyConfirmed}
                onChange={() => setFormConfirmed(!formConfirmed)}
              />{" "}
              Vendor bank details verbally confirmed
            </Label>
          </FormGroup>
          <div style={{ marginBottom: 10, marginTop: 10 }}>
            <Button
              onClick={() => onValidate(account?.id)}
              color="success"
              className={""}
              style={{ marginRight: 10 }}
              disabled={!(approvalEnabled() && formConfirmed)}
            >
              {approvalEnabled()
                ? "Validate"
                : account.status === "Form requested"
                ? "Pending vendor submission"
                : "Validate"}
            </Button>
            <Button
              onClick={() => setToggleAccountModal(true)}
              color="danger"
              style={{ marginRight: 10 }}
              disabled={!cancelEnabled()}
            >
              Cancel
            </Button>
            <Button
              onClick={() => setToggleSendBackToVendorModal(true)}
              color="warning"
              style={{ marginRight: 10 }}
              disabled={!approvalEnabled()}
            >
              Send back to vendor
            </Button>
            <Button
              onClick={() => setToggleReopenFormModal(true)}
              color="warning"
              style={{ marginRight: 10 }}
              disabled={!canReopen()}
            >
              Reopen form
            </Button>
          </div>
          <div>
            <Table bordered striped>
              <thead>
                <tr>
                  <th style={{ width: 200 }}>Name</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Id</th>
                  <td>{account?.id}</td>
                </tr>
                <tr>
                  <th scope="row">Request contact email</th>
                  <td>{account?.requestContactEmail}</td>
                </tr>
                <tr>
                  <th scope="row">Justification</th>
                  <td>{account?.businessJustification}</td>
                </tr>
                <tr>
                  <th scope="row">Vendor name</th>
                  <td>{account?.name}</td>
                </tr>
                <tr>
                  <th scope="row">Sales contact name</th>
                  <td>{account?.salesContactName}</td>
                </tr>
                <tr>
                  <th scope="row">Sales contact mobile</th>
                  <td>{account?.salesContactMobile}</td>
                </tr>
                <tr>
                  <th scope="row">Existing account</th>
                  <td>{account?.accountName}</td>
                </tr>
                <tr>
                  <th scope="row">Account types</th>
                  <td>{accountTypes}</td>
                </tr>
                <tr>
                  <th scope="row">Submitted date</th>
                  <td>
                    {account?.formSubmittedDate == null
                      ? ""
                      : moment(account?.formSubmittedDate).format(
                          "DD-MM-YYYY HH:mm"
                        )}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Address 1</th>
                  <td>{account?.address1}</td>
                </tr>
                <tr>
                  <th scope="row">Address 2</th>
                  <td>{account?.address2}</td>
                </tr>
                <tr>
                  <th scope="row">City</th>
                  <td>{account?.city}</td>
                </tr>
                <tr>
                  <th scope="row">Zip</th>
                  <td>{account?.zip}</td>
                </tr>
                <tr>
                  <th scope="row">Country</th>
                  <td>{account?.country?.country}</td>
                </tr>
                <tr>
                  <th scope="row">Phone</th>
                  <td>{account?.phone}</td>
                </tr>
                <tr>
                  <th scope="row">Sales email</th>
                  <td>{account?.salesEmail}</td>
                </tr>
                <tr>
                  <th scope="row">Finance email</th>
                  <td>{account?.financeEmail}</td>
                </tr>
                <tr>
                  <th scope="row">Payment term</th>
                  <td>{account?.paymentTermName}</td>
                </tr>
                <tr>
                  <th scope="row">Tax registration number</th>
                  <td>{account?.taxRegistrationNumber}</td>
                </tr>
                <tr>
                  <th scope="row">Notes</th>
                  <td>{account?.notes}</td>
                </tr>
                <tr>
                  <th scope="row">Requested by name</th>
                  <td>{account?.requestedByName}</td>
                </tr>
                <tr>
                  <th scope="row">Requested by email</th>
                  <td>{account?.requestedByEmail}</td>
                </tr>
                <tr>
                  <th scope="row">Finance approved by</th>
                  <td>{account?.approvedByFinanceName}</td>
                </tr>
                <tr>
                  <th scope="row">Approved by finance at</th>
                  <td>
                    {" "}
                    {account?.approvedByFinanceAt == null
                      ? ""
                      : moment(account?.approvedByFinanceAt).format(
                          "DD-MM-YYYY HH:mm"
                        )}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Line manager approved by</th>
                  <td>{account?.approvedByLineManagerName}</td>
                </tr>
                <tr>
                  <th scope="row">Approved by Line manager at</th>
                  <td>
                    {" "}
                    {account?.approvedByLineManagerAt == null
                      ? ""
                      : moment(account?.approvedByLineManagerAt).format(
                          "DD-MM-YYYY HH:mm"
                        )}
                  </td>
                </tr>

                <tr>
                  <th scope="row">Finance rejected by</th>
                  <td>{account?.rejectedByFinanceName}</td>
                </tr>
                <tr>
                  <th scope="row">Rejected by finance at</th>
                  <td>
                    {" "}
                    {account?.rejectedByFinanceAt == null
                      ? ""
                      : moment(account?.rejectedByFinanceAt).format(
                          "DD-MM-YYYY HH:mm"
                        )}
                  </td>
                </tr>
                <tr>
                  <th scope="row">Line manager rejected by</th>
                  <td>{account?.rejectedByLineManagerName}</td>
                </tr>
                <tr>
                  <th scope="row">Rejected by Line manager at</th>
                  <td>
                    {" "}
                    {account?.rejectedByLineManagerAt == null
                      ? ""
                      : moment(account?.rejectedByLineManagerAt).format(
                          "DD-MM-YYYY HH:mm"
                        )}
                  </td>
                </tr>

                <tr>
                  <th scope="row">Line manager to notify</th>
                  <td>{account?.lineManagerToNotify}</td>
                </tr>
                <tr>
                  <th scope="row">Finance approver to notify</th>
                  <td>{account?.financeApproverToNotify}</td>
                </tr>
              </tbody>
            </Table>
          </div>

          {account?.isGiro ? (
            <GIRO account={account} />
          ) : (
            <BANK vendorName={account.name} bankDataList={banks} />
          )}

          <h4>Files</h4>
          <div>
            {files?.map((file, index) => (
              <div key={index}>
                <a target="_blank" rel="noopener noreferrer" href={file.url}>
                  {file.source || "Vendor Form"} | {file.displayName}
                </a>
              </div>
            ))}
          </div>
        </ModalBody>
      </Modal>
      <AccountModal
        setToggle={setToggleAccountModal}
        toggle={toggleAccountModal}
        account={account}
        onUpdateAccount={(result) => {
          setAccount(result.data);
          onUpdateAccount(result);
        }}
        // onSendToMDM={onSendToMDM}
      />
      <SendBackToVendorModal
        accountId={account.id}
        setToggle={setToggleSendBackToVendorModal}
        toggleValue={toggleSendBackToVendorModal}
        onSubmit={onSendBackToVendor}
      />
      <ReopenModal
        addAlert={addAlert}
        setToggle={setToggleReopenFormModal}
        toggle={toggleReopenFormModal}
        onSubmit={onReopen}
        accountId={id}
      />
    </div>
  ) : null;
};

const GIRO = (props) => {
  return (
    <div>
      <h4>GIRO</h4>
      <Table bordered striped>
        <tbody>
          <tr>
            <td style={{ width: 200 }}>Account number</td>
            <td>{props.account.accountNumber}</td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

const BANK = ({ bankDataList, vendorName }) => {
  return (
    <div>
      <h4>Bank Data</h4>
      <div>
        {bankDataList?.map((bank, index) => (
          <>
            {bank.beneficiaryBankAccountName?.trim() !== vendorName?.trim() ? (
              <p className="text-danger">
                Vendor name is different from Beneficiary name (usually same as
                Vendor name)
              </p>
            ) : null}
            <Table bordered striped key={index}>
              <tbody>
                <tr>
                  <td scope="row">
                    {" "}
                    Beneficiary name (usually same as Vendor name)
                  </td>
                  <td>{bank.beneficiaryBankAccountName}</td>
                </tr>
                <tr>
                  <td scope="row">Currency</td>
                  <td>{bank.currency.name}</td>
                </tr>
                <tr>
                  <td style={{ width: 200 }}>IBAN</td>
                  <td>{bank.iban}</td>
                </tr>
                <tr>
                  <td scope="row">BIC/SWIFT</td>
                  <td>{bank.bic}</td>
                </tr>
                <tr>
                  <td scope="row">Corresponding BIC/SWIFT</td>
                  <td>{bank.correspondingBIC}</td>
                </tr>
                <tr>
                  <td scope="row">Registration Number</td>
                  <td>{bank.regNo}</td>
                </tr>
                <tr>
                  <td style={{ width: 200 }}>Account number</td>
                  <td>{bank.accountNr}</td>
                </tr>
                <tr>
                  <td scope="row">FIK Kortart</td>
                  <td>{bank.fikKortart}</td>
                </tr>
                <tr>
                  <td scope="row">FIK Kreditor/GIRO</td>
                  <td>{bank.fikNumber}</td>
                </tr>
                <tr>
                  <td scope="row">Bank</td>
                  <td>{bank.bank}</td>
                </tr>
                <tr>
                  <td scope="row">Branch</td>
                  <td>{bank.branch}</td>
                </tr>
                <tr>
                  <td scope="row">Address</td>
                  <td>{bank.address}</td>
                </tr>
                <tr>
                  <td scope="row">City</td>
                  <td>{bank.city}</td>
                </tr>
                <tr>
                  <td scope="row">Zip</td>
                  <td>{bank.zip}</td>
                </tr>
                <tr>
                  <td scope="row">Country</td>
                  <td>{bank.country}</td>
                </tr>
                <tr>
                  <td scope="row">Is primary</td>
                  <td>{bank.isPrimary ? "Yes" : "No"}</td>
                </tr>
                <tr>
                  <td scope="row">Files</td>
                  <td>
                    {bank.files.map((file, index) => (
                      <div key={index}>
                        <a
                          target="_blank"
                          rel="noopener noreferrer"
                          href={file.url}
                        >
                          {file.displayName}
                        </a>
                      </div>
                    ))}
                  </td>
                </tr>
              </tbody>
            </Table>
          </>
        ))}
      </div>
    </div>
  );
};
