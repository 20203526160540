import React, { useEffect, useState } from "react";
import {
  changeContactEmail,
  changeContactMobile,
  changeFinanceApprover,
  changeLineManagerApprover,
} from "../../../utils/queries";

import {
  Button,
  Form,
  Label,
  FormGroup,
  Col,
  Row,
  Modal,
  ModalHeader,
  ModalBody,
  Input,
} from "reactstrap";
import PhoneInput from "react-phone-input-2";
import Alerts from "../../molecules/Alerts";
import v4 from "uuid/dist/v4";
import { EmailSelect } from "../../organisms/EmailSelect";
import { isNullOrEmpty, RequestHistoryType } from "../../../utils/common";

export const ChangeApproverModel = ({
  account,
  toggleModal,
  setToggleModal,
  type,
  onUpdateAccount,
  dataLoader,
  defaultValue,
}) => {
  const [alerts, setAlerts] = useState([]);
  const [approverEmail, setApproverEmail] = useState(undefined);
  const [contactMobile, setContactMobile] = useState(undefined);
  const [contactEmail, setContactEmail] = useState(undefined);
  const [showError, setShowError] = useState(undefined);

  const toggle = () => {
    setToggleModal(!toggle);
    setApproverEmail(undefined);
    setContactEmail(undefined);
    setContactMobile(undefined);
  };

  const addAlert = (message, color) => {
    let id = v4();
    setAlerts([...alerts, { message: message, id: id, color: color }]);
  };

  const handleApproverEmail = (value) => {
    setApproverEmail(value);
  };

  const getNumber = (number = "") => {
    return !number.includes("+") ? `+${number}` : number;
  };

  const submitted = async (e) => {
    e.preventDefault();

    let validated = validate();

    if (!validated) {
      return;
    }
    let response;
    if (type === "Finance approver") {
      response = await changeFinanceApprover(account.id, approverEmail);
    } else if (type === "Line manager") {
      response = await changeLineManagerApprover(account.id, approverEmail);
    } else if (type === RequestHistoryType.contactEmail) {
      response = await changeContactEmail(account.id, contactEmail);
    } else if (type === RequestHistoryType.contactMobile) {
      response = await changeContactMobile(
        account.id,
        getNumber(contactMobile)
      );
    }

    try {
      onUpdateAccount(response);
    } catch (error) {
      addAlert("Could not change request. Please contact IT.", "danger");
    }

    toggle();
  };

  const validate = () => {
    let isSuccess = true;

    setShowError(false);

    if (
      !approverEmail &&
      (type == RequestHistoryType.lineManager ||
        type == RequestHistoryType.financeManager)
    ) {
      setShowError(true);
      isSuccess = false;
    }

    if (
      isNullOrEmpty(contactEmail) &&
      type == RequestHistoryType.contactEmail
    ) {
      setShowError(true);
      isSuccess = false;
    }

    if (
      isNullOrEmpty(contactMobile) &&
      type == RequestHistoryType.contactMobile
    ) {
      setShowError(true);
      isSuccess = false;
    }

    return isSuccess;
  };

  useEffect(() => {
    if (
      !isNullOrEmpty(defaultValue) &&
      type == RequestHistoryType.contactMobile
    ) {
      setContactMobile(defaultValue);
    }
    if (
      !isNullOrEmpty(defaultValue) &&
      type == RequestHistoryType.contactEmail
    ) {
      setContactEmail(defaultValue);
    }
  }, [defaultValue]);

  return account ? (
    <div>
      <Modal isOpen={toggleModal} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>Change {type}</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              <Alerts alerts={alerts} setAlerts={setAlerts} />
            </Col>
          </Row>
          <div style={{ marginBottom: 10 }}>
            <Form onSubmit={submitted}>
              {type == RequestHistoryType.contactEmail ? (
                <FormGroup>
                  <Label className="w-100">{type} to be notified</Label>
                  <Input
                    type="email"
                    name="contactEmail"
                    id="email"
                    placeholder="Contact email"
                    onChange={(e) => setContactEmail(e.target.value)}
                    value={contactEmail}
                  />
                  {showError && isNullOrEmpty(contactEmail) && (
                    <p className="text-danger">Required</p>
                  )}
                </FormGroup>
              ) : type == RequestHistoryType.contactMobile ? (
                <FormGroup>
                  <Label for="contactMobile">{type} to be notified</Label>
                  <PhoneInput
                    placeholder="Contact mobile"
                    value={contactMobile}
                    inputClass="form-control"
                    inputStyle={{
                      width: "100%",
                    }}
                    onChange={setContactMobile}
                  />
                  {showError && isNullOrEmpty(contactMobile) && (
                    <p className="text-danger">Required</p>
                  )}
                </FormGroup>
              ) : (
                <FormGroup>
                  <Label className="w-100">{type} to be notified</Label>
                  <EmailSelect
                    value={approverEmail}
                    onChange={handleApproverEmail}
                    xPageForm
                    className="react-select"
                    dataLoader={dataLoader}
                  />
                  {showError && !approverEmail && (
                    <p className="text-danger">Required</p>
                  )}
                </FormGroup>
              )}

              <Button color="success" className="mr-2 mt-3">
                Change
              </Button>
            </Form>
          </div>
        </ModalBody>
      </Modal>
    </div>
  ) : null;
};
